import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import InputField from '@elements/forms/InputField';
import { useState } from 'react';

import { requestPasswordResetCode } from '@providers/profile';

import Icon from '@elements/Icon';

export default function ResetPasswordModal() {
  const [email, setEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleModalVisibility = () => {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
    setEmail('');
    setShowConfirmation(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  async function onSubmit() {
    const response = await requestPasswordResetCode(email);
    if (response && response.status === 200) {
      setShowConfirmation(true);
    }
  }

  return (
    <div>
      <button
        className="font-primary text-sm font-light leading-5 text-accent underline"
        type="button"
        onClick={toggleModalVisibility}
      >
        Wachtwoord vergeten?
      </button>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        modalContent={
          <>
            {showConfirmation ? (
              <div>
                <h1 className="pb-8 text-xl font-bold">Gelukt!</h1>
                <div>
                  <div className="flex flex-col justify-start pb-12 text-sm md:pb-0">
                    <div className="flex gap-4 rounded-md bg-accent-2 px-4 py-2">
                      <div className="left-0 top-0 py-1">
                        <Icon iconName="check" color="black" />
                      </div>
                      <span>
                        <h2 className="font-primary text-sm">We hebben een link gestuurd naar:</h2>
                        <div className="font-bold">{email}</div>
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-end gap-4 pt-6">
                    <Button
                      label="Naar inloggen"
                      type="button"
                      buttonType="primary"
                      onClick={() => setShowModal(false)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <h1 className="pb-2 text-xl font-bold">Wat is je emailadres?</h1>
                <p className="mb-8 font-primary text-sm">
                  We sturen een link om je wachtwoord te herstellen.
                </p>
                <div>
                  <div className="flex flex-col gap-2">
                    <InputField
                      label="Email"
                      name="email"
                      type="text"
                      placeholder="jan@bouwbedrijf.nl"
                      onChange={handleEmailChange}
                    />
                  </div>
                  <div className="flex justify-end gap-4 pt-6">
                    <Button
                      label="Annuleren"
                      type="button"
                      buttonType="secondary"
                      onClick={() => setShowModal(false)}
                    />
                    <Button
                      label="Versturen"
                      type="button"
                      buttonType="primary"
                      onClick={onSubmit}
                      disabled={email == ''}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        }
      />
    </div>
  );
}
