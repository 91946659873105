import * as Sentry from '@sentry/browser';
import { clientApi } from './clientApi';
import { serverSideApi } from './serverSideApi';

export async function loginUser(params, headers) {
  try {
    return await clientApi('/profile/login', 'POST', null, params, headers);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function loginOauthUser(data) {
  try {
    return await clientApi('/oauth_provider/login', 'POST', null, JSON.stringify(data));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function verifySocialLogin(userDetails) {
  try {
    return await clientApi('/profile/social/verify', 'POST', null, JSON.stringify(userDetails));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function loginSocialUser(social_provider, token) {
  try {
    return await clientApi(
      '/profile/social/login',
      'POST',
      null,
      JSON.stringify({
        social_provider,
        token,
      }),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function createSocialProfile(payload, headers) {
  try {
    return await clientApi('/profile/social', 'POST', null, JSON.stringify(payload), headers);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function testToken() {
  try {
    return await clientApi('/profile/token_test', 'POST');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function testAuthToken(req, res) {
  try {
    return await serverSideApi(req, res, '/profile/token_test', 'POST');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getOauthClient(req, res, client_id) {
  try {
    return await serverSideApi(req, res, `/oauth_provider/oauth_client/${client_id}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}
