import { userStore } from '@context/userStore';
import Logo from '@elements/Logo';
import Button from '@elements/buttons/Button';
import InputField from '@elements/forms/InputField';
import NextLink from '@elements/links/NextLink';
import { decodeJwt } from '@helpers/auth';
import { useEnvContext } from '@helpers/envProvider';
import { handleValidation } from '@helpers/handleValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginUser } from '@providers/auth';
import { getOrganizationIdEnterpriseAdmin } from '@providers/organization';
import ResetPasswordModal from 'feature/profile/ResetPasswordModal';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validationSchema } from './validation';

export default function Login({ isInvite, readerUrl, invitedEmail }) {
  const { environment } = useEnvContext();
  const NEXT_PUBLIC_EXTERNAL_API = environment['NEXT_PUBLIC_EXTERNAL_API'];
  const router = useRouter();
  const { setUserStore } = userStore();
  const [isValid, setIsValid] = useState({});
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(false);

  const { state } = router.query;

  const isCheckoutLogin = router.pathname === '/checkout' && state === 'login';

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: { email: invitedEmail ?? '' },
  });

  function handleValidationWrapper(e, error) {
    handleValidation(e, error, trigger, setIsValid);
  }

  async function onSubmit(data) {
    const { email, password } = data;
    const params = new URLSearchParams({
      username: email.toLowerCase(),
      password: password,
    });

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const response = await loginUser(params, headers);

    if (response && response.status === 401) {
      setInvalidCredentials(true);
      setError('email');
      setError('password');
      return;
    } else if (response && response.status === 403) {
      setIsDeactivated(true);
      return;
    }

    const jwt = response.data.access_token;

    const {
      profileId,
      name,
      bundles,
      subscription,
      claims,
      items,
      is_validated,
      is_active,
      organizations,
    } = decodeJwt(jwt);

    const organizationResponse = await getOrganizationIdEnterpriseAdmin(profileId);

    let organizationId = '';
    if (organizationResponse && organizationResponse.status == 200) {
      organizationId = organizationResponse.data.organization_id;
    }

    setUserStore(
      true,
      name,
      profileId,
      bundles,
      claims,
      items,
      is_validated,
      is_active,
      false,
      organizationId,
      false,
      email,
      subscription,
      false,
      organizations,
    );

    const { redirect } = router.query;
    const redirectUrl = redirect ? redirect : readerUrl ? readerUrl : '/';

    if (isInvite && redirectUrl === '/invite/confirmation') {
      router.push(
        {
          pathname: redirectUrl,
          query: router.query,
        },
        '/invite/confirmation',
      );
    } else if (isCheckoutLogin) {
      // This will run when a user is prompted to login during checkout flow
      // At this point the login is successful, so they can be redirected to cart
      router.push({
        pathname: router.pathname,
        query: { state: 'account' },
      });
    } else {
      router.push(redirectUrl);
    }
  }

  useEffect(() => {
    function checkValidity(fields) {
      trigger(fields).then((valid) => {
        const updatedIsValid = { ...isValid };
        for (const field of fields) {
          updatedIsValid[field] = valid;
        }
        setIsValid(updatedIsValid);
      });
    }

    const subscription = watch((value, { name }) => {
      switch (name) {
        case 'email':
          if (value.password) {
            checkValidity(['password']);
          }
          return;
        case 'password':
          // On run the validation if the login is not part of the invite flow
          // Assumption can be made that during the invite flow, the email is always valid
          if (value.email && !invitedEmail) {
            checkValidity(['email']);
          }
          return;
      }
    });

    return () => subscription.unsubscribe();
  }, [isValid]);

  return (
    <form className="flex flex-col gap-4 pt-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-2">
          <h2 className="text-xl font-bold">Welkom terug</h2>
          {!isInvite && (
            <div className="text-sm">
              Nog geen profiel?{' '}
              <span>
                <NextLink
                  linkType="primary"
                  href={
                    isCheckoutLogin
                      ? '/profile/create?flow=checkout&state=register'
                      : '/profile/create'
                  }
                  overrideClass="text-sm"
                >
                  Maak er hier een aan.
                </NextLink>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="max-w-[26rem] text-sm font-light text-functional-error">
          {invalidCredentials && 'Ongeldige gebruikersnaam of wachtwoord.'}
          {isDeactivated && 'Profiel is niet actief.'}
        </div>
        <InputField
          label="Email"
          name="email"
          type="text"
          placeholder="jan@bouwbedrijf.nl"
          register={register}
          onKeyUp={(e) => handleValidationWrapper(e, errors.email)}
          isValid={isValid['email']}
          message={errors.email?.message}
          errors={errors}
          disabled={invitedEmail ? true : false}
          onFocus={() => setInvalidCredentials(false)}
        />
        <InputField
          label="Wachtwoord"
          name="password"
          type="password"
          placeholder="Wachtwoord"
          register={register}
          onKeyUp={(e) => handleValidationWrapper(e, errors.password)}
          isValid={isValid['password']}
          errors={errors}
          message={errors.password?.message}
          onFocus={() => setInvalidCredentials(false)}
        />
        <div className="-mt-2 flex place-items-center justify-between">
          <div className="flex flex-row">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="mt-0.5 size-4 rounded accent-accent"
            />
            <label
              htmlFor="remember-me"
              className="ml-2 block font-primary text-sm font-light text-primary-dark"
            >
              Ingelogd blijven
            </label>
          </div>
          <ResetPasswordModal />
        </div>
        <Button buttonType="primary" type="submit" label="Inloggen" overrideClass="!max-w-full">
          Inloggen
        </Button>
      </div>
      {/* Socials */}
      <div className="relative mt-4">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 font-primary font-light text-primary-dark">
            Of ga door met:
          </span>
        </div>
      </div>
      {/* MD+ */}
      <div className="mb-4 flex flex-col justify-between gap-4 sm:flex-row">
        <NextLink
          overrideClass="group rounded-md px-4 py-2.5 text-primary-dark shadow-md hover:bg-[#EA4335] sm:w-1/2 sm:text-sm md:text-base"
          href={`${NEXT_PUBLIC_EXTERNAL_API}/oauth/google/login`}
        >
          <div className="flex items-center justify-center gap-4 sm:gap-2">
            <div className="w-8">
              <Logo.google.svg className="w-4 group-hover:text-white/90"></Logo.google.svg>
            </div>
            <div className="flex gap-1 text-sm group-hover:text-white/90">
              <span className="flex sm:hidden">Inloggen met</span>Google
            </div>
          </div>
        </NextLink>

        <NextLink
          overrideClass="group rounded-md px-4 py-2 text-primary-dark shadow-md hover:bg-[#00ADEF] sm:w-1/2 sm:text-sm md:text-base"
          href={`${NEXT_PUBLIC_EXTERNAL_API}/oauth/microsoft/login`}
        >
          <div className="flex items-center justify-center gap-4 sm:gap-2">
            <div className="w-8">
              <Logo.microsoft.svg className="w-4 text-[#00ADEF] group-hover:text-white/90 md:mt-1"></Logo.microsoft.svg>
            </div>
            <div className="flex gap-1 text-sm group-hover:text-white/90">
              <span className="flex sm:hidden">Inloggen met</span>Microsoft
            </div>
          </div>
        </NextLink>

        <NextLink
          overrideClass="group rounded-md px-4 py-2 text-primary-dark shadow-md hover:bg-[#000000]/80 sm:w-1/2 sm:text-sm md:text-base"
          href={`${NEXT_PUBLIC_EXTERNAL_API}/oauth/surfconext/login`}
        >
          <div className="flex items-center justify-center gap-4 sm:gap-2">
            <div className="w-8">
              <Logo.surfconext.svg className="h-4 text-[#00ADEF] group-hover:text-primary-dark"></Logo.surfconext.svg>
            </div>
            <div className="flex gap-1 text-sm group-hover:text-white/90">
              <span className="flex sm:hidden">Inloggen met</span>SurfConext
            </div>
          </div>
        </NextLink>
      </div>
    </form>
  );
}
